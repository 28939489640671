import { AxiosRequestConfig, AxiosResponse } from 'axios'
import HttpService, { HttpServiceConfig } from './httpService'
import { getBearerFromCookies } from '@/helpers/auth'
import { accessTokenCookieName, refreshTokenCookieName } from '@/constants'
import Cookies from 'universal-cookie'

export interface HttpAuthServiceConfig extends HttpServiceConfig {
  cookies: Cookies,
  frontBaseUrl: string
}

class HttpAuthService extends HttpService {
  cookies: Cookies

  frontBaseUrl: string

  constructor(config: HttpAuthServiceConfig) {
    super(config)
    this.cookies = config.cookies
    this.frontBaseUrl = config.frontBaseUrl
  }

  override handlerRequestConfig = async (config: AxiosRequestConfig) => {
    this.requestLoggerInfo(config)
    config = this.setBearerFromCookies(config)
    return config
  }

  public get cookieOptions() {
    return { 
      path: '/',
      domain: this.frontBaseUrl.replace(/https?:\/\/(www|local)/g, '')
    }
  }

  setBearerFromCookies = (config: any) => {
    this.loggerConsole?.info(`${this.name} > setBearerFromCookies`)
    this.logger?.info(`${this.name} > setBearerFromCookies`)
    try {
      if (!config) {
        throw new Error('Missing param config')
      }
      const { headers } = config
      const bearerToken = getBearerFromCookies(this.cookies)
      if (bearerToken) {
        headers.Authorization = bearerToken
      }
      return { ...config, headers }
    } catch (error: any) {
      const errorMessage = `${this.name} > setBearerFromCookies ${error.message}`
      this.loggerConsole?.error(errorMessage)
      this.logger?.error(errorMessage)
      throw error
    }
  }

  fetchToken = async (
    params?: {
      refreshToken: string
    },
    getAnonymousTokenOnError = false
  ): Promise<AxiosResponse<{ access_token: string; refresh_token: string }>> => {
    const log = `${this.name} > fetchToken - ${params ? 'refreshToken' : 'accessToken'}`
    this.loggerConsole?.info(`\x1b[34m ${log}\x1b[0m`)
    this.logger?.info(`${log}`)
    try {
      if (!this.httpProxyService) {
        throw new Error('httpProxyService is required')
      }
      const response = await this.httpProxyService.client.post('/token', params)
      if (response.data.access_token) { 
        this.cookies.remove(accessTokenCookieName, this.cookieOptions)
        this.cookies.set(accessTokenCookieName, response.data.access_token, this.cookieOptions)
      }
      if (response.data.access_token) { 
        this.cookies.remove(refreshTokenCookieName, this.cookieOptions)
        this.cookies.set(refreshTokenCookieName, response.data.refresh_token, this.cookieOptions)
      }
      return response
    } catch (error: any) {
      const errorMessage = `${this.name} > fetchToken ${error.message}, ${error?.config?.baseURL}, ${error?.config?.url}`
      this.loggerConsole?.error(errorMessage)
      this.logger?.error(errorMessage)

      // Fallback to prevent being stuck with unhandled refreshToken errors
      if (getAnonymousTokenOnError) {
        const retryMessage = `${this.name} > retry fetchToken`
        this.loggerConsole?.info(retryMessage)
        this.logger?.info(retryMessage)

        return this.fetchToken()
      } else {
        // Last resort to prevent user from being blocked from accessing Forms due to corrupted token causing unhandled errors
        // The page is expected to crash but should be available after a refresh
        this.removeAuthenticationCookies()

        throw error
      }
    }
  }

  /**
   * ### Set Bearer
   * @param config
   * @returns
   */
  setBearerFromCookieOrRefresh = async (config: AxiosRequestConfig): Promise<any> => {
    this.loggerConsole?.info(`\x1b[34m ${this.name} > setBearerFromCookieOrRefresh \x1b[0m`)
    this.logger?.info(`${this.name} > setBearerFromCookieOrRefresh`)
    try {
      if (!config) {
        throw new Error('Missing param config')
      }
      // Get valid token
      let authorizationBearer = getBearerFromCookies(this.cookies)

      // If token is expired, fetch new with refresh token
      if (!authorizationBearer) {
        const refreshToken = this.cookies.get(refreshTokenCookieName)
        const params = refreshToken ? { refreshToken } : undefined
        const { data } = await this.fetchToken(params, !!refreshToken)

        const { access_token } = data
        authorizationBearer = `Bearer ${access_token}`
      }

      // Set Headers Authorization
      config.headers.Authorization = authorizationBearer
      return config
    } catch (error: any) {
      const errorMessage = `${this.name} > setBearerFromCookieOrRefresh ${error.message}, ${
        error?.config?.baseURL || ''
      }${error?.config?.url || ''}`
      this.loggerConsole?.error(errorMessage)
      this.logger?.error(errorMessage)

      throw error
    }
  }

  removeAuthenticationCookies() {
    const currentDomain = process?.env?.NUXT_ENV_BASE_URL?.match(
      /https?:\/\/(?:[^/]+\.)?([^/]+(?:\.[^/]+)+)/
    )
    const cookieDomain = `.${currentDomain?.[1] ?? ''}`

    this.cookies.remove(accessTokenCookieName, this.cookieOptions)
    this.cookies.remove(refreshTokenCookieName, this.cookieOptions)
  }
}

export default HttpAuthService
